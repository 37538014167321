import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=>import('@/views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/career',
    name:'career',
    component: () => import(/* webpackChunkName: "about" */ '../views/Career.vue')
  },
  {
    path:'/project',
    name:'project',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectView.vue')
  },
  {
    path:'/project/:id',
    name:'project_id',
    component: () => import(/* webpackChunkName: "about" */ '../components/project/ProjectDetail.vue')
  },
  {
    path: '/news-events',
    name: 'news-events',
    component: () => import('../views/News&Events.vue')
  },
  {
    path: '/news-details',
    name: 'news-details',
    component: () => import('../views/NewDetails.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/apply/:id',
    name: 'apply',
    component: () => import('../views/Apply.vue')
  },
  {
    path: '/service/:id',
    name: 'service',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/Services.vue')
  },
  {
    path: '/services_detail',
    name: 'services_detail',
    component: () => import('../views/ServicesDetail.vue')
  },
  {
    path: '/term_condition',
    name: 'term_condition',
    component: () => import('../views/TermCondition.vue')
  },
  {
    path: '/privacy_policy',
    name: 'privacy_policy',
    component: () => import('../views/PrivacyPolicy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
