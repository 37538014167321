<template>
  <header-section></header-section>
  <router-view/>
  <footer-section></footer-section>
</template>

<script setup>
import {MainPage} from '@/store/index'
import { defineAsyncComponent,beforeMount, onMounted} from 'vue'
import * as external from '@/external_link/external.js'
const HeaderSection = defineAsyncComponent(()=>import('@/layouts/Header.vue'))
const FooterSection = defineAsyncComponent (()=>import('@/layouts/Footer.vue'))
const store = MainPage()

onMounted(()=>{
  store.GetImages()
})
</script>
<style>

</style>
