import { defineStore } from 'pinia'
import API from '@/config/API.js'
import showToast from '@/config/Toast'
export const MainPage = defineStore('MainPage', {
    state: () => {
        return {
         data:'',
         load:false,
         reviews:'',
         services:'',
         singleService:'',
         team:'',
         serviceDetail:'',
         detail:[],
         content:'',
         career:'',
         singleCareer:'',
         projects:'',
         singleProject:'',
         images:'',
        }
      },
  actions: {

    async Getservices(){
      const response = await API.get("services/GetBy/Al-Sharaf")
      if(response.status==200)
      {
        this.services =response.data
       
        this.GetservicesD(this.services[0].serviceD[0])
      }           
 },
 async GetServiceById(id){
  const response = await API.get("services/GetById/"+id)
  if(response.status==200)
  {
    this.singleService = response.data
    this.GetservicesD(this.singleService.serviceD[0])
  }           
},
 async GetservicesD(id){
  const response = await API.get("service-detail/GetById/"+id)
  if(response.status==200)
  {
    this.serviceDetail =response.data.detail
  }           
},


    async GetTeam(){
     const response = await API.get("team-def?page=1&limit=100")
     if(response.status==200)
     {
       this.team=response.data.results
     }           
},

async GetImages(){
  const response = await API.get("image-media/GetBy/Al-Sharaf")
  if(response.status==200)
  {
    this.images=response.data
  }           
},

async GetContent(){
  const response = await API.get("contpage/GetBy/Al-Sharaf")
  if(response.status==200)
  {
    this.content=response.data
  }           
},



async GetDetail(){
  const response = await API.get("company-details/GetBy/Al-Sharaf")
  if(response.status==200)
  {
    this.detail=response.data[0]
  }           
},

async GetReview(){
  const response = await API.get("review/GetBy/Al-Sharaf")
  if(response.status==200)
  {
    this.reviews =response.data
  }           
},

async GetCareer(){
  const response = await API.get("career/GetBy/Al-Sharaf")
  if(response.status==200)
  {
    this.career =response.data
  }           
},
async SCareer(payload){
  const response = await API.get("career/GetById/"+payload)
  if(response.status==200)
  {
    this.singleCareer = response.data
  }           
},


async GetProject(){
  const response = await API.get("project/GetBy/Al-Sharaf")
  if(response.status==200)
  {
    this.projects =response.data
  }           
},

async GetProjectById(payload){
  const response = await API.get("project/GetById/"+payload)
  if(response.status==200)
  {
    this.singleProject = response.data
  }           
},


    async ContactUs(payload){
      try{
        this.load = true
        const response = await API.post("/contact-Us",payload)
        if(response.status==200)
        {
         this.load = false
         showToast('query submit successfully','success')
         return true
        }     
      }catch(error){
        this.load= false
      }
               
    },

    async Apply(payload){
      try{
        const formData = new FormData();
        Object.keys(payload).forEach(key => formData.append(key, payload[key]));
        this.load = true
       const response = await API.post("/career-detail",formData)
       if(response.status==200)
       {
         this.load = false
        showToast('Application submit successfully','success')
        return  true
       }        
  }catch(error){
    this.load= false
  }
    }
    
  },
  })