import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'
const pinia = createPinia()
import VueLazyLoad from 'vue3-lazyload'
const app = createApp(App)
app.use(router)
app.use(pinia)
.use(VueLazyLoad, {
    // options...
  })
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDNS7nXnbp8nqbAzU5h1nuqAK5kaWAsP90',
        // language: 'de',
    }
})
app.mount('#app')
